import React from "react";
import jsonData from "../data/data.json";
import mystVaultImg from '../images/png-js-game-transparent-bg.png';

const MysteryVault = () => {
  const data = jsonData[2];
  // console.log(data.title);

  return (
    <section className="project-content" id="mystery-content">
      <img src={mystVaultImg} alt="Mystery Vault Site" />

<h1 className="project-title">{data?.title}</h1>
{/* <h2 className="project-subheading">{data?.subheading}</h2> */}

<section className="project-details">
  <h3>Overview</h3>
  <p className='overview'>{data.overview}</p>

  <div className="btn-group-container">
    <button className="project-btn">
      <a href={data.livesite}>Live Site</a>
    </button>
    <button className="project-btn">
      <a href={data.github}>GitHub</a>
    </button>
  </div>

  <div id='cards'>
    {data.role && (
      <article className="card-info card-role">
        <h4>Role</h4>
        <ul className='list'>
          {data.role.map((item) => <li key={item}>{item}</li>)}
        </ul>
      </article>
    )}
    <article className="card-info card-duration">
      <h4>Duration</h4>
      <p>{data.duration}</p>
    </article>

    {data.tools && (
      <article className="card-info card-tools">
        <h4>Tools</h4>
        <ul className='list'>
          {data.tools.map((item) => <li key={item}>{item}</li>)}
        </ul>
      </article>
    )}
  </div>

</section>

{data?.planning && (
  <section className="section-content-planning">
    {data?.planning?.map((content, index) => (
      <div key={index} className="proj-planning-content">
        <h2>{content?.title}</h2>
        <p>{content?.description}</p>
      </div>
    ))}
  </section>
)}
    </section>
  );
};

export default MysteryVault;
